import React from 'react';
import { getLayout } from 'src/layouts/SiteLayout/StaticPageLayout';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

const Custom404 = () => {
  const { t } = useTranslation();
  return <h1 className="text-center">404 | {t('Page is not found')}</h1>;
};

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
    },
  };
}

Custom404.getLayout = getLayout;

export default Custom404;
