import React from 'react';

import { Container } from 'reactstrap';

import styles from '../../assets/css/SiteLayout.module.css';
import EmptyPageLayout from './EmptyPageLayout';

const SiteLayout = ({ children }) => {
  return (
    <Container fluid={true} className={styles.content}>
      {children}
    </Container>
  );
};

export const getLayout = (page) => (
  <EmptyPageLayout>
    <SiteLayout>{page}</SiteLayout>
  </EmptyPageLayout>
);

export default SiteLayout;
