import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { getLayout as getSiteLayout } from './index';

import styles from '../../assets/css/SiteLayout.module.css';

const StaticPageLayout = ({ children }) => {
  return (
    <Container>
      <Row className="justify-content-center align-content-center">
        <Col className={styles.contentWrapper + ' col-12 col-lg-10 my-3 my-lg-5 py-2 py-lg-4 px-3 px-lg-5'}>
          {children}
        </Col>
      </Row>
    </Container>
  );
};

export const getLayout = (page) => getSiteLayout(<StaticPageLayout>{page}</StaticPageLayout>);

export default StaticPageLayout;
